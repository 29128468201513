import React from 'react';
import { Chat } from 'stream-chat-react';
import { StreamChat } from 'stream-chat';
//import { v4 as uuidv4 } from 'uuid';

import 'stream-chat-react/dist/css/index.css';

import './App.css';

import { AgentApp } from './AgentApp';
//import JenAvatar from './assets/jen-avatar.png';
import KevinAvatar from './assets/user.png';
//import User1 from './assets/user1.png';
//import { AgentHeader } from './components/AgentHeader/AgentHeader';
//import { AgentLoading } from './components/AgentLoading/AgentLoading';
import { useChecklist } from './ChecklistTasks';
import { CustomerApp } from './CustomerApp';

const urlParams = new URLSearchParams(window.location.search);
const apiKey = process.env.REACT_APP_STREAM_KEY;
// const agentChannelId = `agent-demo-${uuidv4()}`;
// const customerChannelId = `customer-demo-${uuidv4()}`;
const exhibitor = urlParams.get('exhibitor')  || 'open';
const targetOrigin = urlParams.get('target_origin') || '*';
const theme = 'light';

const userType = urlParams.get('utype') || 'visitor';

const user = urlParams.get('user');
const token = urlParams.get('token');
const name = urlParams.get('name');

const members = urlParams.get('mem') || '';

const channelId = `ub-${exhibitor}-${user}`;

//Init Client
const client = StreamChat.getInstance(apiKey);

//Connect visitors chat to instance
client.connectUser(
  {
    id: user,
    name: name,
    image: KevinAvatar,
    phone: '+1 (303) 555-1212',
    email: 'kevin@example.com',
  },
  token,
);

const App = () => {

  useChecklist(client, targetOrigin);

  return (
    <>
      <div className='agent-wrapper'>
      {userType === 'agent' ?
          <>
{/*
            <AgentHeader />
*/}
            <Chat client={client}>
              <AgentApp {...{ user, channelId, exhibitor, name }} />
            </Chat>
          </>
        :
        <Chat client={client} theme={`commerce ${theme}`}>
          <CustomerApp {...{ channelId, exhibitor, name, members}} />
        </Chat>
      }
      </div>
    </>
  );
};

export default App;
