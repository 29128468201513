import React from 'react';

import './CustomerChannelHeader.css';

// import { ClockIcon } from '../../assets';
// import user3 from '../../assets/user3.png';
// import user2 from '../../assets/user2.png';
// import user1 from '../../assets/user1.png';

export const CustomerChannelHeader = () => (
  <div className='channel-header__container'>
    <div className='channel-header__heading'>
      <div className='channel-header__text'>
        <p className='channel-header__name'>
          Hej
          <span role='img' aria-label='waving-hand'>
            👋
          </span>
        </p>
        <p className='channel-header__subtitle'>Vad kan vi hjälpa dig med</p>
      </div>
    </div>
   {/* <div className='channel-header__wait__wrapper'>
      <ClockIcon />
      <p className='channel-header__wait__text'>
        Average wait time: <b>2 minutes</b>
      </p>
    </div>*/}
  </div>
);
